export interface IAlternatives {
	customerData: ICustomerData;
	offerList: IOfferList[] | [];
	alternativeSelected: IAlternativeSelected;
	index: number;
	error: boolean;
  code: string
}

export interface ICustomerData {
	expirationDate: string;
	alternativeType: string;
	state: string;
	customerName: string;
	mobilePhone: string;
	callDays: string;
	actionCode: number;
  opportunityId: string;
  obligaciones: string[];
  newMobilePhone : string;
}

export interface IOfferList {
	packageName: string;
	needCode: number;
	alternativeConditions: string;
	creditsNumber: number;
	feeDuringGracePeriod: string;
	newAmortizationDescription: string;
	details: IDetails;
}

export interface IAlternativeSelected {
	packageName: string;
	needCode: number;
	alternativeConditions: string;
	creditsNumber: number;
	feeDuringGracePeriod: string;
	newAmortizationDescription: string;
	details: IDetails;
}

export interface IDetails {
	newConditions: INewConditions;
	creditsIncluded: ICreditsIncluded[];
}

export interface INewConditions {
	term: string;
	gracePeriod: string;
	newFee: string;
	feeAfterGracePeriod: string;
	unifiedValueFNG: string;
	feeFNG: string;
	newCapital: string;
	rate: string;
}

export interface ICreditsIncluded {
	nameObligation: string;
	loanNumber: string;
  loanNumberComplete: string;
}

export const initialStateAlternatives: IAlternatives = {
	customerData: {
		expirationDate: "",
		alternativeType: "",
		state: "",
		customerName: "",
		mobilePhone: "",
		callDays: "",
		actionCode: 0,
    opportunityId: "",
    obligaciones: ["",""],
    newMobilePhone : ""
	},
	offerList: [
		{
			packageName: "",
			creditsNumber: 0,
      needCode: 0,
		  alternativeConditions: "",
			feeDuringGracePeriod: "",
			newAmortizationDescription: "",
			details: {
				newConditions: {
					term: "",
					gracePeriod: "",
					newFee: "",
					feeAfterGracePeriod: "",
					unifiedValueFNG: "",
					feeFNG: "",
					newCapital: "",
					rate: ""
				},
				creditsIncluded: [
					{
						nameObligation: "",
						loanNumber: "",
            loanNumberComplete: ""
					}
				]
			}
		}
	],
	alternativeSelected: {
		packageName: "",
		creditsNumber: 0,
		needCode: 0,
		alternativeConditions: "",
		feeDuringGracePeriod: "",
		newAmortizationDescription: "",
		details: {
			newConditions: {
				term: "",
				gracePeriod: "",
				newFee: "",
				feeAfterGracePeriod: "",
				unifiedValueFNG: "",
				feeFNG: "",
				newCapital: "",
				rate: ""
			},
			creditsIncluded: [
				{
					nameObligation: "",
					loanNumber: "",
          loanNumberComplete: ""
				}
			]
		}
	},
	index: 0,
	error: false,
  code: "000"
};

export const LogAlternatives: IAlternatives = {
	customerData: {
		expirationDate: "",

		alternativeType: "Consolidacion",
		state: "",
		customerName: "RAMON",
		mobilePhone: "",
		callDays: "",
		actionCode: 0,
    opportunityId: "",
    obligaciones: ["",""],
    newMobilePhone : ""
	},
	offerList: [
		{
			packageName: "",
      needCode: 0,
      alternativeConditions: "",
			creditsNumber: 0,
			feeDuringGracePeriod: "",
			newAmortizationDescription: "",
			details: {
				newConditions: {
					term: "",
					gracePeriod: "",
					newFee: "",
					feeAfterGracePeriod: "",
					unifiedValueFNG: "",
					feeFNG: "",
					newCapital: "",
					rate: ""
				},
				creditsIncluded: [
					{
						nameObligation: "",
						loanNumber: "",
            loanNumberComplete: ""
					}
				]
			}
		},
    {
			packageName: "",
      needCode: 0,
      alternativeConditions: "",
			creditsNumber: 0,
			feeDuringGracePeriod: "",
			newAmortizationDescription: "",
			details: {
				newConditions: {
					term: "",
					gracePeriod: "",
					newFee: "",
					feeAfterGracePeriod: "",
					unifiedValueFNG: "",
					feeFNG: "",
					newCapital: "",
					rate: ""
				},
				creditsIncluded: [
					{
						nameObligation: "",
						loanNumber: "",
            loanNumberComplete: ""
					}
				]
			}
		}
	],
	alternativeSelected: {
		packageName: "",
		creditsNumber: 0,
    needCode: 0,
    alternativeConditions: "",
		feeDuringGracePeriod: "",
		newAmortizationDescription: "",
		details: {
			newConditions: {
				term: "",
				gracePeriod: "",
				newFee: "",
				feeAfterGracePeriod: "",
				unifiedValueFNG: "",
				feeFNG: "",
				newCapital: "",
				rate: ""
			},
			creditsIncluded: [
				{
					nameObligation: "",
					loanNumber: "",
          loanNumberComplete: ""
				}
			]
		}
	},
	index: 0,
	error: false,
  code: "000"
};

export const offerSV: IAlternatives = {
	customerData: {
		expirationDate: "",
		alternativeType: "",
		state: "",
		customerName: "",
		mobilePhone: "",
		callDays: "",
		actionCode: 0,
    opportunityId: "",
    obligaciones:["",""],
    newMobilePhone : ""
	},
	offerList: [
		{
			packageName: "",
      needCode: 0,
      alternativeConditions: "",
			creditsNumber: 0,
			feeDuringGracePeriod: "",
			newAmortizationDescription: "",
			details: {
				newConditions: {
					term: "",
					gracePeriod: "",
					newFee: "",
					feeAfterGracePeriod: "",
					unifiedValueFNG: "",
					feeFNG: "",
					newCapital: "",
					rate: ""
				},
				creditsIncluded: [
					{
						nameObligation: "",
						loanNumber: "",
            loanNumberComplete: ""
					},
          {
						nameObligation: "",
						loanNumber: "",
            loanNumberComplete: ""
					}
				]
			}
		},
    {
			packageName: "",
      needCode: 0,
      alternativeConditions: "",
			creditsNumber: 0,
			feeDuringGracePeriod: "",
			newAmortizationDescription: "",
			details: {
				newConditions: {
					term: "",
					gracePeriod: "",
					newFee: "",
					feeAfterGracePeriod: "",
					unifiedValueFNG: "",
					feeFNG: "",
					newCapital: "",
					rate: ""
				},
				creditsIncluded: [
					{
						nameObligation: "",
						loanNumber: "",
            loanNumberComplete: ""
					}
				]
			}
		}
	],
	alternativeSelected: {
		packageName: "",
		creditsNumber: 0,
    needCode: 0,
    alternativeConditions: "",
		feeDuringGracePeriod: "",
		newAmortizationDescription: "",
		details: {
			newConditions: {
				term: "",
				gracePeriod: "",
				newFee: "",
				feeAfterGracePeriod: "",
				unifiedValueFNG: "",
				feeFNG: "",
				newCapital: "",
				rate: ""
			},
			creditsIncluded: [
				{
					nameObligation: "",
					loanNumber: "",
          loanNumberComplete: ""
				}
			]
		}
	},
	index: 0,
	error: false,
  code: "000"
};
