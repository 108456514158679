import {
	InCacheAlternativesAction,
	EAlternativesInCache
} from "../actions/alternativeInCache.action";
import { initialStateAlternativesCache } from "../states/alternativesCache.state";

export function alternativesInCacheReducer(
	state = initialStateAlternativesCache,
	action: InCacheAlternativesAction
) {
	switch (action.type) {
		case EAlternativesInCache.LOAD_IN_CACHE_ALTERNATIVES_SUCCESS: {
			const { customerData } = action.payload;
			return {
				...state,
				customerData,
				error: false
			};
		}
		case EAlternativesInCache.LOAD_IN_CACHE_ALTERNATIVES_FAIL: {
			const {error, code} = action.payload;
      return {
				...state,
				error,
        code
			};
		}
		default:
			return state;
	}
}
