import {
	ClientOkAction,
	EClientOk
} from "../actions/clientOk.action";
import { initialStateClientOk} from '../states/clientOk.state';

export function clientOkReducer(
	state = initialStateClientOk,
	action: ClientOkAction
) {
	switch (action.type) {
		case EClientOk.LOAD_CLIENT_OK_SUCCESS: {
      return {
				...state,
        ...action.payload,
			};
		}
		case EClientOk.LOAD_CLIENT_OK_FAIL: {
			return {
				...state,
				clientOk : action.payload
			};
		}
		default:
			return state;
	}
}
