import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from "@angular/core";
import { CoreModule } from "./core/core.module";
import {
  HttpClientModule,
	provideHttpClient,
	withInterceptorsFromDi
} from "@angular/common/http";
import { BrowserModule } from "@angular/platform-browser";
import { StoreModule, Store } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { appReducer } from "./store/reducers/app.reducer";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LogoutComponent } from "./layout/components/logout/logout.component";
import { SystemNotAvailableComponent } from "./layout/components/system-not-available/system-not-available.component";
import { registerLocaleData } from "@angular/common";
import localeEs from "@angular/common/locales/es";
import { BannerComponent } from "./layout/components/banner/banner.component";

registerLocaleData(localeEs);

@NgModule({
	declarations: [
		AppComponent,
    LogoutComponent,
    SystemNotAvailableComponent,
    BannerComponent
	],
	imports: [
		BrowserModule,
		CoreModule,
		AppRoutingModule,
		HttpClientModule,
		StoreModule.forRoot(appReducer),
		StoreDevtoolsModule.instrument({
			maxAge: 10,
      connectInZone: true
		})
	],

	providers: [
    CoreModule,
    AppRoutingModule,
    Store,
    provideHttpClient(withInterceptorsFromDi()),
    { provide: LOCALE_ID, useValue: "es" }
  ],


	bootstrap: [AppComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
