export interface INegotiationInfo{
  data : IDataNegotiation;
}

export interface IDataNegotiation{
  client : IClientInfo;
  alternatives: IAlternativesNegotiation[]; //alternativas
}

export interface IClientInfo{
  documentNumber: number;
  documentType: string;
  statusNegotiation: string;
  name:  string;
  mail: string;
}

export interface IAlternativesNegotiation{
  nameAlternative: string;
  descAlternative: string;
  statusAlternative: string;
  maxValuePagare: string;
  dataAlternative : IDataAlternative[]; //listado de conceptos
}

export interface IDataAlternative{ //concepto
  numberConsolidate : string;
  concepto: string;
  plan : string;
  valuePagare : string;
  documents : IDocument[];
}

export interface IDocument{
  nameDocument : string;
  statusDocument : string;
  numDocument : string;
  typeDocument : string;
}

export interface IAlternativesNegSelected{
  nameAlternative: string;
  descAlternative: string;
  statusAlternative: string;
  maxValuePagare: string;
  dataAlternative : IDataAlternative[];
  indexAlternativa : number;
  indexConcepto : number;
}

export const initialStateNegotiation: INegotiationInfo = {
  data: {
      client: {
          documentNumber: 0,
          documentType: "",
          statusNegotiation: "",
          name: "",
          mail: ""
      },
      alternatives: [
          {
              nameAlternative: "",
              descAlternative: "",
              statusAlternative: "",
              maxValuePagare: "",
              dataAlternative: [
                  {
                      numberConsolidate: "",
                      concepto: "",
                      plan: "",
                      valuePagare :"",
                      documents: [
                          {
                              nameDocument: "",
                              statusDocument: "",
                              numDocument: "",
                              typeDocument: ""
                          },
                          {
                              nameDocument: "",
                              statusDocument: "",
                              numDocument: "",
                              typeDocument: ""
                          },
                          {
                              nameDocument: "",
                              statusDocument: "",
                              numDocument: "",
                              typeDocument: ""
                          }
                      ]
                  }
              ]
          },
          {
            nameAlternative: "",
            descAlternative: "",
            statusAlternative: "",
            maxValuePagare: "",
            dataAlternative: [
                {
                    numberConsolidate: "",
                    concepto: "",
                    plan: "",
                    valuePagare :"",
                    documents: [
                        {
                            nameDocument: "",
                            statusDocument: "",
                            numDocument: "",
                            typeDocument: ""
                        },
                        {
                            nameDocument: "",
                            statusDocument: "",
                            numDocument: "",
                            typeDocument: ""
                        },
                        {
                            nameDocument: "",
                            statusDocument: "",
                            numDocument: "",
                            typeDocument: ""
                        }
                    ]
                }
            ]
        }
      ]
  }
}

export const intialStateData : IDataNegotiation ={
  client: {
      documentNumber: 0,
      documentType: "",
      statusNegotiation: "",
      name: "",
      mail: ""
  },
  alternatives: [
      {
          nameAlternative: "",
          descAlternative: "",
          statusAlternative: "",
          maxValuePagare: "",
          dataAlternative: [
              {
                  numberConsolidate: "",
                  concepto: "",
                  plan: "",
                  valuePagare :"",
                  documents: [
                      {
                          nameDocument: "",
                          statusDocument: "",
                          numDocument: "",
                          typeDocument: ""
                      },
                      {
                          nameDocument: "",
                          statusDocument: "",
                          numDocument: "",
                          typeDocument: ""
                      },
                      {
                          nameDocument: "",
                          statusDocument: "",
                          numDocument: "",
                          typeDocument: ""
                      }
                  ]
              }
          ]
      }
  ]
}

export const initialAlternative :IAlternativesNegotiation[] = [
  {
      nameAlternative: "",
      descAlternative: "",
      statusAlternative: "",
      maxValuePagare: "",
      dataAlternative: [
          {
              numberConsolidate: "",
              concepto: "",
              plan: "",
              valuePagare :"",
              documents: [
                  {
                      nameDocument: "",
                      statusDocument: "",
                      numDocument: "",
                      typeDocument: ""
                  },
                  {
                      nameDocument: "",
                      statusDocument: "",
                      numDocument: "",
                      typeDocument: ""
                  },
                  {
                      nameDocument: "",
                      statusDocument: "",
                      numDocument: "",
                      typeDocument: ""
                  }
              ]
          }
      ]
  },
  {
    nameAlternative: "",
    descAlternative: "",
    statusAlternative: "",
    maxValuePagare: "",
    dataAlternative: [
        {
            numberConsolidate: "",
            concepto: "",
            plan: "",
            valuePagare :"",
            documents: [
                {
                    nameDocument: "",
                    statusDocument: "",
                    numDocument: "",
                    typeDocument: ""
                },
                {
                    nameDocument: "",
                    statusDocument: "",
                    numDocument: "",
                    typeDocument: ""
                },
                {
                    nameDocument: "",
                    statusDocument: "",
                    numDocument: "",
                    typeDocument: ""
                }
            ]
        }
    ]
}
]

