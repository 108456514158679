@if (isFirma) {
  <app-stroke-firma></app-stroke-firma>
}
<bc-header [headerType]="'bc-header-wrapper-black'" *ngIf="hiddenNav">
  <bc-logo  size="220px"  name="bancolombia-horizontal-negative" [ariaHidden]="false" alt="logo"></bc-logo>
	<div *ngIf="!isFirma" class="contx">
		<bc-icon
      id="icono-salir"
			class="loli"
			name="quit"
			aria-hidden="true"
			aria-label="Salir"
			(click)="abrirModal()"
		>
		</bc-icon>
	</div>
</bc-header>

<app-modal-salir *ngIf="hiddenNav" [open]="openN" (closeModal)="openN=false"></app-modal-salir>
