import { Action } from "@ngrx/store";
import { IclientInfo } from "../states/client-info.state";

export enum EClientInfo {
	LOAD_CLIENTNINFO_SUCCESS = "[ClientInfOk] get Info Success",
	LOAD_CLIENTNINFO_FAIL = "[ClientInfOk] get Info Failed",
}

export class LoadClientInfoSuccess implements Action {
	readonly type = EClientInfo.LOAD_CLIENTNINFO_SUCCESS;
	constructor(public payload: IclientInfo) {}
}
export type ClientAction =
	| LoadClientInfoSuccess;
