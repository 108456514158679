import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Store } from "@ngrx/store";
import { DATA_MODAL_TOMATE_TIEMPO_SALIR } from "src/app/core/components/modal/constants";
import { IAppState } from "src/app/store/states/app.state";
import { DataModal } from "src/assets/interfaces/interfaces.state";
import { transformDateExpire } from "../../../utils/utils";
import { MantenimientosService } from "src/app/services/mantenimientos.service";

declare let KAMPYLE_ONSITE_SDK: any;
const ZERO = 0;

@Component({
	selector: "app-modal-salir",
	templateUrl: "./modal-salir.component.html"
})
export class ModalSalirComponent implements OnInit {
	private _open: boolean = false;
	@Output() closeModal = new EventEmitter<string>();
	public dataxModalCancelar: DataModal = DATA_MODAL_TOMATE_TIEMPO_SALIR;
	public expirationDate: string = "";
	public documentNumber: string = "";

	@Input()
	set open(value: boolean) {
		this.getExpirationDate();
		setTimeout(() => {
			this.dataxModalCancelar.tex1Part2 =
				"estarán esperando por ti hasta el " + this.expirationDate;
		});

		this._open = value;
	}
	get open(): boolean {
		return this._open;
	}

	constructor(
		private _store: Store<IAppState>,
		private mantenimientosService: MantenimientosService
	) {}
	ngOnInit(): void {
		this.getDocument();
	}

	getExpirationDate(): void {
		let tempDate = "";
		this._store
			.select((state) => state.alternatives.customerData.expirationDate)
			.subscribe((expirationDate) => {
				if (expirationDate !== "") {
					this.expirationDate = transformDateExpire(expirationDate);
					tempDate = expirationDate;
				}
			});
		if (tempDate === "") {
			this._store
				.select(
					(state) =>
						state.Maintenance_Info.customerData
							.expirationDateFormatted
				)
				.subscribe((expirationDate) => {
					this.expirationDate = this.formatDate(expirationDate);
				});
		}
	}

	formatDate(date: string): string {
		return date.split("/").reverse().join("/");
	}

	cerrarModalConfirmar(event: boolean) {
		if (event) {
			this.closeModal.emit("");
		}
	}

	logoutMantenimientos() {
    	console.log("logoutMantenimientos");
		const currentURL = document.location.href;
		const mantenimientosPaths = [
			"mantenimientos/listado-creditos",
			"mantenimientos/opcion-pago",
			"mantenimientos/nuevas-condiciones",
			"mantenimientos/otra-opcion",
			"mantenimientos/detalle",
			"mantenimientos/terminos-condiciones",
			"mantenimientos/confirmar-contacto"
		];
		if (mantenimientosPaths.some(path => currentURL.includes(path))) {
      		this.showSurvey();
    	} else {
			this.exitExperience();
		}
	}

	showSurvey() {
		window.scrollTo({ top: ZERO, behavior: "smooth" });
		window["userExit"] = true;
		window["IdCliente"] = this.documentNumber;
 try{
   if (
     KAMPYLE_ONSITE_SDK &&
     typeof KAMPYLE_ONSITE_SDK.updatePageView === "function"
		) {
      KAMPYLE_ONSITE_SDK.updatePageView();
			window.addEventListener("MDigital_Form_Close_No_Submit", () => {
        this.exitExperience();
			});
			window.addEventListener("MDigital_ThankYou_Close", () => {
        this.exitExperience();
			});
		}
  }catch(error){
    console.log("Error al cargar la encuesta de abandono");
    this.exitExperience();
  }
	}

	getDocument() {
		this._store
			.select((state) => state.autenticated.data)
			.subscribe((response) => {
				this.documentNumber = response.documentNumber;
			});
	}

	exitExperience() {
		this.mantenimientosService.logoutService().subscribe(() => {
			window.location.href =
				"https://www.bancolombia.com/personas/alternativas-financieras/negociar-deuda";
		});
	}
}
