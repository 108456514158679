import {
	AlternativesActions,
	EAlternatives
} from "../actions/alternatives.action";
import { initialStateAlternatives } from "../states/alternatives.state";

function loadAlternativesSuccess(state, action) {
	const { customerData, offerList } = action.payload;
	return {
	  ...state,
	  customerData,
	  offerList,
	  error: false
	};
  }
  
  function loadAlternativeSelected(state, action) {
	return {
	  ...state,
	  alternativeSelected: action.payload,
	  error: false
	};
  }
  
  function loadAlternativeIndex(state, action) {
	return {
	  ...state,
	  index: action.payload,
	  error: false
	};
  }
  
  function loadAlternativesFail(state) {
	return {
	  ...state,
	  error: true
	};
  }
  
  function updateAlternativesNewMobilePhoneState(state, action) {
	const { newMobilePhone } = action.payload;
	return {
	  ...state,
	  customerData: {
		...state.customerData,
		newMobilePhone: newMobilePhone
	  }
	};
  }
  
  export function alternativesReducer(state = initialStateAlternatives, action: AlternativesActions) {
	switch (action.type) {
	  case EAlternatives.LOAD_ALTERNATIVES_SUCCESS:
		return loadAlternativesSuccess(state, action);
	  case EAlternatives.LOAD_ALTERNATIVE_SELECTED:
		return loadAlternativeSelected(state, action);
	  case EAlternatives.LOAD_ALTERNATIVE_INDEX:
		return loadAlternativeIndex(state, action);
	  case EAlternatives.LOAD_ALTERNATIVES_FAIL:
		return loadAlternativesFail(state);
	  case EAlternatives.UPDATE_ALTERNATIVES_NEWMOBILEPHONE_STATE:
		return updateAlternativesNewMobilePhoneState(state, action);
	  default:
		return state;
	}
  }
