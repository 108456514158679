import { Action } from "@ngrx/store";

export enum EClientOk {
	LOAD_CLIENT_OK_SUCCESS = "[ClientOk] get ClientOk Success",
	LOAD_CLIENT_OK_FAIL = "[ClientOk] get ClientOk Failed"
}

export class LoadClientOkSuccess implements Action {
	readonly type = EClientOk.LOAD_CLIENT_OK_SUCCESS;
	constructor(public payload: any) {}
}

export class LoadClientOkFailed implements Action {
	readonly type = EClientOk.LOAD_CLIENT_OK_FAIL;
	constructor(public payload: any) {}
}

export type ClientOkAction =
	| LoadClientOkSuccess
	| LoadClientOkFailed;
