import { Action } from "@ngrx/store";
import { IAlternativesCache } from "../states/alternativesCache.state";

export enum EAlternativesInCache {
	LOAD_IN_CACHE_ALTERNATIVES_SUCCESS = "[Alternatives] get AlternativesInCache Success",
	LOAD_IN_CACHE_ALTERNATIVES_FAIL = "[Alternatives] get AlternativesInCache Failed"
}

export class LoadInCacheAlternativesSuccess implements Action {
	readonly type = EAlternativesInCache.LOAD_IN_CACHE_ALTERNATIVES_SUCCESS;
	constructor(public payload: IAlternativesCache | any) {}
}

export class LoadInCacheAlternativesFailed implements Action {
	readonly type = EAlternativesInCache.LOAD_IN_CACHE_ALTERNATIVES_FAIL;
	constructor(public payload: any) {}
}

export type InCacheAlternativesAction =
	| LoadInCacheAlternativesSuccess
	| LoadInCacheAlternativesFailed;
