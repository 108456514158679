<div class="container-system-not-available">
	<div class="container-last-time">
		<p class="last-time">
			<span class="capitalize">{{day}} </span>{{dayNext}} {{month}}
			{{monthNext}} {{timeHours}}
		</p>
	</div>
	<div class="container-icon-power-cord">
		<bc-icon
			aria-hidden="true"
			name="power cord"
			aria-label="system_not_available"
			role="img"
			width="220px"
			class="bc-sm"
		></bc-icon>
	</div>
	<div class="container-title">
		<h4 class="title-system-not-available">
			El sistema no se encuentra disponible
		</h4>
	</div>
	<div class="container-text-info">
		<p class="text-system-not-available">
			Nuestro equipo esta trabajando para brindarte una pronta solución, por favor inténtalo mas tarde.
		</p>
	</div>
  <div class="codeError">
		<p class="">
			Código de error
		</p>
    <p class="cod">
			{{codeError}}
		</p>
	</div>
	<div class="container-btn-exit">
		<button
			bc-button
			typeButton="primary"
			sizeButton="small"
			class="button-exit"
			(click)="exitExperience()"
		>
			SALIR
		</button>
	</div>
</div>
