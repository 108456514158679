import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { environment } from "src/environments/environment";
import { IAlternatives } from "../layout/cobranza-digital/store/states/alternatives.state";
import { IAlternativesCache } from "../layout/cobranza-digital/store/states/alternativesCache.state";
import {
	  IData,
	initialStateIData,
	IAutenticated,
  IAutenticatedRedirection
} from "../layout/cobranza-digital/store/states/autenticated.state";
import { IClientOk } from "../layout/cobranza-digital/store/states/clientOk.state";

import { typeDcmt } from "../utils/utils";
import { Store } from "@ngrx/store";
import { IAppState } from "../store/states/app.state";
import { DuplicateSession } from "../layout/cobranza-digital/store/actions/authorize.action";
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class CobranzaService {
	public endpoint: string = environment.apiEndPointCobranza;
	public url: string = environment.urlServices;
	private userData: IAutenticated;

	bodyClient = {
		documentType: "",
		documentNumber: ""
	};

	bodyOffer = {
		documentType: 0,
		documentNumber: 0
	};

	bodyAuth = {
		data: [
			{
				header: {
					id: "12345",
					type: "string"
				},
				authorization_code: ""
			}
		]
	};

	bodyGenerateCodeAuth = {
		data: [
			{
				idNumber: "",
				documentType: "CC",
				pinBlock: ""
			}
		]
	};

	constructor(
		private http: HttpClient,
		private _store: Store<IAppState>
	) {
		this._store
			.select((state) => state.autenticated)
			.subscribe((autenticated) => {
				this.userData = autenticated;
			})
	}

	getHeader = (tokenInput: string = "", tokenFua: string = "") => {
		return new HttpHeaders({
			"Content-Type": "application/json",
			"Content-Encoding": "gzip, deflate, br",
			jwtInput: tokenInput,
			jwtFua: tokenFua
		});
	};

	getAlternatives(dat: IData = initialStateIData): Observable<IAlternatives> {
		this.bodyOffer.documentNumber = +dat.documentNumber;
		this.bodyOffer.documentType = +typeDcmt(dat.documentType);
		return this.http.post<IAlternatives>(
			`${this.url}/ms-offer/portfolioOffers`,
			JSON.stringify(this.bodyOffer),
			{
				headers: this.getHeader(dat.transactionToken, dat.sessionToken)
			}
		); // para hacer pruebas en local hay que comentar esta linea [LOCALPRUB]
		// return this.http.get<any>(
		//   `https://run.mocky.io/v3/77ff8b58-e49e-4ca6-ad3a-7a6fbb40212e`
		// ); // para hacer pruebas en local hay que habilitar esta linea [LOCALPRUB]
	}

	getClientServiceOK(dat: IData = initialStateIData): Observable<IClientOk> {
		this.bodyClient.documentNumber = `${dat.documentNumber}`;
		this.bodyClient.documentType = typeDcmt(dat.documentType);
		return this.http.post<IClientOk>(
			`${this.url}/ms-client/client`,
			JSON.stringify(this.bodyClient),
			{
				headers: this.getHeader(dat.transactionToken, dat.sessionToken)
			}
		);
	}

	getSaveInCacheAlternativesService(
		dat: IData = initialStateIData
	): Observable<IAlternativesCache> {
		this.bodyOffer.documentNumber = +dat.documentNumber;
		this.bodyOffer.documentType = +typeDcmt(dat.documentType);
		return this.http.post<IAlternativesCache>(
			`${this.url}/ms-offer/offer`,
			JSON.stringify(this.bodyOffer),
			{
				headers: this.getHeader(dat.transactionToken, dat.sessionToken)
			}
		);
	}

  defineExperience(dataUserSession: any)
		: Observable<any> {
		return this.http.post<any>(
			`${this.url}/ms-offer/offer`,
        {
          "documentType": +typeDcmt(dataUserSession.documentType),
          "documentNumber": dataUserSession.documentNumber
        }
      ,
			{
				headers: this.getHeader(dataUserSession.transactionToken, dataUserSession.sessionToken)
			}
		);
  }

	clientStatusUpdate(op: string, dat: IData): Observable<string> {
		const bodyStatusUp = {
			opportunityId: op
		};
		return this.http.post<string>(
			`${this.url}/ms-offer/statusUpdate`,
			JSON.stringify(bodyStatusUp),
			{
				headers: this.getHeader(dat.transactionToken, dat.sessionToken)
			}
		);
	}

	getAutenticate(code: string = ""): Observable<IAutenticated> {
		this.bodyAuth.data[0].authorization_code = code;
		return this.http.post<IAutenticated>(
			`${this.url}/ms-auth/authorize`,
			JSON.stringify(this.bodyAuth),
			{
				headers: this.getHeader()
			}
		);
	}

	generateAuthCode(
		cc: string,
		pinBlock: string,
		typeDc: string
	): Observable<any> {
		this.bodyGenerateCodeAuth.data[0].idNumber = cc;
		this.bodyGenerateCodeAuth.data[0].pinBlock = pinBlock;
		this.bodyGenerateCodeAuth.data[0].documentType = typeDc;

		return this.http.post<any>(
			`${this.url}/ms-auth/codes`,
			JSON.stringify(this.bodyGenerateCodeAuth),
			{
				headers: this.getHeader()
			}
		);
	}

	getContactService(dat: IData): Observable<any> {
		this.bodyOffer.documentNumber = +dat.documentNumber;
		this.bodyOffer.documentType = +typeDcmt(dat.documentType);
		return this.http.post(
			`${this.url}/ms-offer/portfolioOffers`,
			this.bodyOffer,
			{
				headers: this.getHeader(
					dat.transactionToken,
					dat.transactionToken
				),
				observe: "response"
			}
		);
	}

	getLogoutService(accTk: string, dcmt: string): Observable<any> {
		const losgaut: any = {
			accessToken: accTk,
			documentNumber: dcmt
		};

		return this.http.post(`${this.url}/ms-auth/logOut`, losgaut, {
			headers: this.getHeader(),
			observe: "response"
		});
	}

	setAdminfo(bodySQS: any, dat: IData): Observable<any> {
		return this.http.post(`${this.url}/ms-offer/sendsqs`, bodySQS, {
			headers: this.getHeader(dat.transactionToken, dat.sessionToken)
		});
	}

	logs(bodyLogs: any,  dat: IData): Observable<string | object> {
		return this.http.post<object>(
			`${this.url}/ms-logs/register`,
			JSON.stringify(bodyLogs),
			{
				headers: this.getHeader(dat.transactionToken, dat.sessionToken)
			}
		).pipe(
			catchError((error) => {
				this.dispatchDuplicateSession(
					error.error?.errorDetails?.functionalErrorCode
				);
				return "The service register log has failed";
			})
		)
	}

  	routing(code: string = ""): Observable<IAutenticatedRedirection> {
    	const codeBody = {
			"authorization_code": code
		};
		return this.http.post<IAutenticatedRedirection>(
			`${this.url}/routing/consult`,
			codeBody
		);
	 }

	private dispatchDuplicateSession(functionalErrorCode: string){
		if (functionalErrorCode === "AUTH010") {
			this._store.dispatch(new DuplicateSession(true));
		}
	}

}

