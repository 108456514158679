import { Action } from "@ngrx/store";
import { IAlternatives, IOfferList } from "../states/alternatives.state";

export enum EAlternatives {
	LOAD_ALTERNATIVES_SUCCESS = "[Alternatives] get Alternatives Success",
	LOAD_ALTERNATIVE_SELECTED = "[Alternatives] get Alternatives Selected",
	LOAD_ALTERNATIVE_INDEX = "[Alternatives] get Alternatives Index",
	LOAD_ALTERNATIVES_FAIL = "[Alternatives] get Alternatives Failed",
  UPDATE_ALTERNATIVES_NEWMOBILEPHONE_STATE = "[UpdateNewMobilePhoneOk] update NewMobilePhone state",
}

export class LoadAlternativesSuccess implements Action {
	readonly type = EAlternatives.LOAD_ALTERNATIVES_SUCCESS;
	constructor(public payload: IAlternatives | any) {}
}

export class LoadAlternativeSelected implements Action {
	readonly type = EAlternatives.LOAD_ALTERNATIVE_SELECTED;
	constructor(public payload: IOfferList | any) {}
}

export class LoadAlternativeIndex implements Action {
	readonly type = EAlternatives.LOAD_ALTERNATIVE_INDEX;
	constructor(public payload: any) {}
}

export class LoadAlternativesFailed implements Action {
	readonly type = EAlternatives.LOAD_ALTERNATIVES_FAIL;
	constructor(public payload: any) {}
}

export class UpdateNewMobilePhoneState implements Action {
	readonly type = EAlternatives.UPDATE_ALTERNATIVES_NEWMOBILEPHONE_STATE;
	constructor(public payload: {newMobilePhone: string }) {}
}

export type AlternativesActions =
	| LoadAlternativesSuccess
	| LoadAlternativeSelected
	| LoadAlternativeIndex
	| LoadAlternativesFailed
  | UpdateNewMobilePhoneState;
