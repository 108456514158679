//Librerias de angular
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";

//Componentes del banco
import { BcLogoModule } from "@bancolombia/design-system-web/bc-logo";
import { BcAccordionModule } from "@bancolombia/design-system-web/bc-accordion";
import { BcInputModule } from "@bancolombia/design-system-web/bc-input";
import { BcStepperModule} from "@bancolombia/design-system-web/bc-stepper";
import { BcInputSelectModule } from '@bancolombia/design-system-web/bc-input-select'
import {BcInputDateModule} from '@bancolombia/design-system-web/bc-input-date'
import {BcInputTokenModule} from "@bancolombia/design-system-web/bc-input-token";
import {BcIconButtonModule} from "@bancolombia/design-system-web/bc-icon-button";

//Components
import { FooterComponent } from "./components/footer/footer.component";
import { NavbarComponent } from "./components/navbar/navbar.component";

import { BcIconModule } from "@bancolombia/design-system-web/bc-icon";
import { BcFooterModule } from "@bancolombia/design-system-web/bc-footer";
import { BcHeaderModule } from "@bancolombia/design-system-web/bc-header";
import { BcButtonModule } from "@bancolombia/design-system-web/bc-button";
import { BcCardModule } from "@bancolombia/design-system-web/bc-card";
import { BcCarouselModule } from "@bancolombia/design-system-web/bc-carousel";
import { BcModalModule } from "@bancolombia/design-system-web/bc-modal";
import { BcTooltipModule } from "@bancolombia/design-system-web/bc-tooltip";
import { BcNotificationModule } from "@bancolombia/design-system-web/bc-notification";
import { BcAlertModule } from "@bancolombia/design-system-web/bc-alert";
import { BcPreloaderModule } from "@bancolombia/design-system-web/bc-preloader";
import { BcLoaderModule } from "@bancolombia/design-system-web/bc-loader";
import { BcProgressBarWebModule } from "@bancolombia/design-system-web/bc-progress-bar";
import { CardSimpleComponent } from "./components/card-simple/card-simple.component";
import { ModalComponent } from "./components/modal/modal.component";
import { StrokesComponent } from "./components/strokes/strokes.component";
import {BcInlineAlertModule} from '@bancolombia/design-system-web/bc-inline-alert';
import { BcRadioModule } from "@bancolombia/design-system-web/bc-radio";
import {BcCheckboxModule} from '@bancolombia/design-system-web/bc-checkbox';
import { ModaEspComponent } from "./components/moda-esp/moda-esp.component";
import { IconoCargaComponent } from "./components/icono-carga/icono-carga.component";
import { StrokeFirmaComponent } from "../layout/firma-electronica/componentes/stroke-firma/stroke-firma.component";
import {NgxExtendedPdfViewerModule} from "ngx-extended-pdf-viewer";
import { BcPictogramModule } from "@bancolombia/design-system-web/bc-pictogram";
import { ModalSalirComponent } from "./components/modal-salir/modal-salir.component";
import { ModalActuContactComponent } from "./components/modal-actu-contact/modal-actu-contact.component";
import { ModalActuDatosComponent } from "./components/modal-actu-datos/modal-actu-datos.component";
import { BcInputSelectV2Module } from "@bancolombia/design-system-web/bc-input-select-v2";

@NgModule({
	declarations: [
		NavbarComponent,
		FooterComponent,
		CardSimpleComponent,
		ModalComponent,
		StrokesComponent,
    ModaEspComponent,
    IconoCargaComponent,
    ModalSalirComponent,
		ModaEspComponent,
		IconoCargaComponent,
    StrokeFirmaComponent,
		ModalSalirComponent,
		ModalActuContactComponent,
		ModalActuDatosComponent
	],
	imports: [
		RouterModule,
		CommonModule,
		BcIconModule.forRoot({
			path: "https://library-sdb.apps.bancolombia.com/bds/6.54.4"
		}),
		BcFooterModule,
		BcHeaderModule,
		BcButtonModule,
		BcCardModule,
		BcCarouselModule,
		BcModalModule,
		BcTooltipModule,
    BcProgressBarWebModule,
    BcNotificationModule,
    BcAlertModule,
    BcPreloaderModule,
    BcLoaderModule,
    BcLogoModule.forRoot({
			path: "https://library-sdb.apps.bancolombia.com/bds/6.54.4"
		}),
		BcInputSelectV2Module,
		BcProgressBarWebModule,
		BcNotificationModule,
		BcAlertModule,
		BcPreloaderModule,
		BcLoaderModule,
		BcAccordionModule,
		BcInputModule,
    BcCheckboxModule,
    BcInputSelectModule,
    BcInputDateModule,
    BcStepperModule,
    BcInputTokenModule,
    BcIconButtonModule,
    BcRadioModule,
    NgxExtendedPdfViewerModule,
	],

	exports: [
		NavbarComponent,
		FooterComponent,
		CardSimpleComponent,
		ModalComponent,
		ModaEspComponent,
		IconoCargaComponent,
		BcButtonModule,
		BcFooterModule,
		BcIconModule,
		BcCardModule,
		BcCarouselModule,
		BcInputSelectV2Module,
		BcModalModule,
		BcTooltipModule,
		StrokesComponent,
    BcProgressBarWebModule,
    BcNotificationModule,
    BcAlertModule,
    BcPreloaderModule,
    BcLoaderModule,
    BcLogoModule,
    BcInlineAlertModule,
    BcRadioModule,
    BcCheckboxModule,
    ModalSalirComponent,
		BcProgressBarWebModule,
		BcNotificationModule,
		BcAlertModule,
		BcPreloaderModule,
		BcLoaderModule,
		BcLogoModule,
		BcAccordionModule,
		BcInputModule,
		BcRadioModule,
    BcCheckboxModule,
    BcInputSelectModule,
    BcInputDateModule,
    BcStepperModule,
    BcInputTokenModule,
    BcPictogramModule,
    BcIconButtonModule,
    NgxExtendedPdfViewerModule,
		ModalSalirComponent,
		ModalActuContactComponent,
		ModalActuDatosComponent
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	providers: []
})
export class CoreModule {}
