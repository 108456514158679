export interface SimulationData {
  simulation: Simulation;
  tyc: string;
  nameTyc: string
}

interface Simulation {
  dates: Dates[];
  installments : Installments[];
}

interface Dates{
  nextPaymentDate: string;
  extension: number;
}
interface Installments{
  installmentLifeAndUnemployment: number;
  termExtension: number;
}



export const initialStateSimulationData: SimulationData = {
    simulation: {
      dates: [
        {
          nextPaymentDate: "",
          extension: 0
        }
      ],
      installments: [
        {
          installmentLifeAndUnemployment: 0,
          termExtension: 0
        }
      ]
    },
    tyc: "",
    nameTyc: ""
  }
