import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LogoutComponent } from "./layout/components/logout/logout.component";
import { SystemNotAvailableComponent } from "./layout/components/system-not-available/system-not-available.component";
import { IconoCargaComponent } from "./core/components/icono-carga/icono-carga.component";
import { PermissionsGuard2 } from "./guard/permissions2.guard";
import { authMttsGuard } from "./guard/auth-mtts.guard";
import { BannerComponent } from "./layout/components/banner/banner.component";

const routes: Routes = [
	{
    path: '',
    loadChildren: () =>
      import('./layout/cobranza-digital/cobranza-digital.module').then(
        (m) => m.CobranzaDigitalModule
      ),
  },
  {
    path: 'mantenimientos',
    loadChildren: () =>
      import('./layout/mantenimientos/mantenimientos.module').then(
        (m) => m.MantenimientosModule
      ),
      canActivate: [authMttsGuard]
  },
  {
    path: 'firmaelectronica',
    loadChildren: () =>
      import('./layout/firma-electronica/firma-electronica.module').then(
        (m) => m.FirmaElectronicaModule
      ),
  },
  {
		path: "banner",
		component: BannerComponent,
		canActivate: [PermissionsGuard2]
	},
  { path: "logout", component: LogoutComponent },
	{ path: "error", component: SystemNotAvailableComponent },
  { path: "cargando",  component: IconoCargaComponent },
  { path: "", redirectTo: "cargando", pathMatch: "full" },
	{ path: "**", redirectTo: "cargando" },
  { path: " ", redirectTo: "cargando", pathMatch: "full" }
];
//proyecto
@NgModule({
	imports: [
		RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' } )
	],
	exports: [RouterModule],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppRoutingModule {}
