import {
	AfterViewInit,
	Component,
	ElementRef,
	HostListener,
	Inject,
	OnInit
} from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";

import {
	DATA_CUENTA_REGRE,
	DATA_EXPIROSESION
} from "./core/components/moda-esp/constants";


import {
	DataModal,
	DataModalEsp
} from "src/assets/interfaces/interfaces.state";
import { DATA_MODAL_SESSION_DUPLICATE } from "./core/components/modal/constants";
import { Store } from "@ngrx/store";
import { IAppState } from "src/app/store/states/app.state";
import {
	LoadAutenticatedFailed,
	LoadAutenticatedSuccess,
  LoadClientSuccess,
} from "./layout/cobranza-digital/store/actions/autenticated.action";
import { LoadClientOkFailed } from "./layout/cobranza-digital/store/actions/clientOk.action";
import { IClientOk } from "./layout/cobranza-digital/store/states/clientOk.state";
import { CobranzaService } from "./services/cobranza.service";
import { LOG1, LOG2} from "./utils/constOfTest";
import { generateLog, ChangeGuard } from "./utils/utils";
import { Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';

import { LOCATION_TOKEN } from "./utils/location";
import { MantenimientosService } from "./services/mantenimientos.service";
import { LoadInCacheAlternativesSuccess } from "./layout/cobranza-digital/store/actions/alternativeInCache.action";
import { LoadAuthorize } from "./layout/cobranza-digital/store/actions/authorize.action";

const CIEN_PORCIENTO: number = 100;
const ZERO: number = 0;
const SIXTY: number = 60;
const THOUSAND: number = 1000;
const MAX_TIME_INACTIVITY: number = 420; //7 MINUTOS 420
const ALERT_TIME_INACTIVITY: number = 120; //2 MINUTOS 120

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	providers: [{ provide: LOCATION_TOKEN, useValue: window.location }]
})
export class AppComponent implements OnInit, AfterViewInit {
	@HostListener("window:pageshow", ["$event"])
	leaving($event: any) {
		if ($event.persisted) {
			this.router.navigate(["/logout"]);
		}
	}
	dataModal: DataModal = DATA_MODAL_SESSION_DUPLICATE;
	dataM: DataModalEsp = DATA_EXPIROSESION;
	dataM2: DataModalEsp = DATA_CUENTA_REGRE;
	viewBtnModal: boolean = false;
	viewBtnExpire: boolean = false;
	viewBtnCuentRegresiva: boolean = false;
	public closeModalInativity: boolean = false;

	openModalSessionExpire: boolean = false;
	openModalCuentaRegresiva: boolean = false;
	duplicateSession: boolean = false;

	minutes: string;
	seconds: string;

	openSessionExpire: boolean = false;
	openCuentRegresiva: boolean = false;

	guard: ChangeGuard;
	mostrarFooter: boolean = true;
	documentNumber: string;
	documentType: string;

	public controlInactivity: any;

	public maxTimeInactivity: number = MAX_TIME_INACTIVITY;
	public alertTimeInactivity: number = ALERT_TIME_INACTIVITY;
	public isMovingMouse: boolean = false;
	public countSecondsInactivity: number = ZERO;
  public code: string = "";
  public data : any;

  redirection : string;
  mostrarCarga: boolean= false;
  textoCarga : string = "Por favor espera mientras validamos la información";

  count: number = 0;
  countExpire: number = 0;
  moviendo: boolean = false;

	constructor(
		private readonly cobranzaService: CobranzaService,
		private readonly mantenimientosService: MantenimientosService,
		private readonly router: Router,
		private readonly _store: Store<IAppState>,
    @Inject(LOCATION_TOKEN) public location: Location,
    private readonly _elementRef: ElementRef,
		private readonly route: ActivatedRoute,
    private readonly titleService: Title
	) {
    this.guard = new ChangeGuard(this._store, this.location);
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        const currentRoute = this.router.routerState.snapshot.root;
        this.setTitle(currentRoute);
      });
  }

  setTitle(route: any) {
    const urlFirma= "firmaelectronica";
    let title = "Cobranza digital";
    const url = route._routerState.url ;
     if(route.queryParams.trace !== undefined || url.includes(urlFirma)){
      title = "Firma electrónica opciones de pago";
    };
    this.titleService.setTitle(title);
  }

	ngOnInit() {
    this._elementRef.nativeElement.removeAttribute("ng-version");
		const querystring = window.location.search;
		const params = new URLSearchParams(querystring);
		const code: string = params.get("code");
		this.dowSesion(code);
		scrollTo(0, 0);
		this.userInactive();
		this.checkMoveMouse();
		this.checkTrace();
		this.checkDuplicateSession();
	}

	ngAfterViewInit(): void {
    this.checkRoute();
	}

	checkTrace() {
		this.route.queryParams.subscribe((params) => {
			const trace = params["trace"];
			if (trace != null) {
				sessionStorage.setItem("trace", trace);
      }
    });
	}

  dowSesion(code: string){
    if(code === "logout"){
      this.router.navigate(["/logout"]);
    }
  }

  checkRoute() {
		const route$ = this.router.events.subscribe(() => {
      console.log("this.router.url", this.router.url);
			const querystring = window.location.search;
      const trace = querystring.includes("trace");
			const params = new URLSearchParams(querystring);
			const code: string = params.get("code");
      console.log("window url querystring", querystring);
      console.log("PARAMS", params);
      console.log("TRACE", trace);
			if (code) {
        this.code = code;
				route$.unsubscribe();
        if(trace){
          console.log("ENTRA IF (trace) checkroute", trace);
          this.callRouting(code);
        }else{
          console.log("else if checkroute callAutenticatedService");
          this.callAutenticatedService(code);
        }
			}
		});
	}

	callAutenticatedService(code: string) {
    console.log("entra callAutenticatedService");
		//Client
		let tmp: any;
		const datax: any = {
			data: {
				paco: ""
			}
		};
		if (code && code !== "logout") {
      console.log("entra if callAutenticatedService linea193")
			this.cobranzaService.getAutenticate(code).subscribe({
				next: (response) => {
					tmp = response["data"];
					datax.data = tmp[0];
					this.documentNumber = response.data[0].documentNumber;
					this.documentType = response.data[0].documentType;
					this._store.dispatch(new LoadAutenticatedSuccess(datax));
					this._store.dispatch(new LoadAuthorize(true));
          this.getClient(tmp[0]);
				},
				error: (e: IClientOk) => {
					this._store.dispatch(new LoadClientOkFailed(e));
					this._store.dispatch(new LoadAutenticatedFailed(true));
					this.createLogError(0, e);
					if (
						e.error.errorDetails.functionalErrorCode === "AUTH010"
					) {
						this.duplicateSession = true;
					}
					this.router.navigate(["/error"]);
				}
			});
		}
	}

  getClient(data : any){
    this.mantenimientosService
						.getClientServiceOK(
							this.documentType,
							this.documentNumber
						)
						.subscribe((resp) => {

              if(resp.codeError){
                console.log("error client app component",resp);
                this._store.dispatch(new LoadClientOkFailed(resp));
                console.log("error al consumir client en callAutenticatedService");
                this.router.navigate(["/error"], { queryParams: { code: resp.codeError } });
              }else{
                console.log(" consume getClientServiceOK, y defineExperience", resp);
                this.defineExperience(data);
                this._store.dispatch(new LoadClientSuccess(resp));
              }
						}
          );
  }
  callRouting(code: string){
    this.mostrarFooter = false;
    let tmp : any;
    const datax : any = {data: {paco : ""}}
    if(code!=="logout"){
      console.log("entra if callrouting");
      this.cobranzaService.routing(code).subscribe({
          next: (response) => {
            tmp = response['data'];
            datax.data = tmp[0];
            this.data = tmp[0];
            this.documentNumber = response.data[0].documentNumber;
            this.documentType = response.data[0].documentType;
            this._store.dispatch(new LoadAutenticatedSuccess(datax));
					  this._store.dispatch(new LoadAuthorize(true));
            this.redireccion(response.experiencia, datax);
          },
          error: (e : IClientOk) => {
            console.log("error callRouting", e);
            this._store.dispatch(new LoadClientOkFailed(e));
            this._store.dispatch(new LoadAutenticatedFailed(true));
            this.createLogError(0, e);
            if (e.error.errorDetails.functionalErrorCode === "AUTH010") {
              this.duplicateSession = true;
            }
            this.mostrarFooter = true;
            this.router.navigate(["/error"]);
          }
		});
    }
  }

  redireccion(experiencia : any, data: any){
    console.log("entra redireccion experiencia:", experiencia);
    this.redirection = experiencia;
    this._store.dispatch(new LoadAutenticatedSuccess(data));
    this.mostrarFooter = true;
      if(this.redirection ==="Firma"){
        console.log("entra por firma en redireccion")
        this.router.navigate(["firmaelectronica/listado-negociaciones"]);
      } else if (this.redirection ==="Offer"){
        console.log("entra offer en redireccion llama callautenticated");
        this.getClient(this.data);
      }else{
        this.router.navigate(["firmaelectronica/no-documentos"]);
      }
  }

  createLogError(paso: number, err : IClientOk) {
    const { bodyLogs} = generateLog(paso, LOG1, LOG2, err);
      this.cobranzaService.logs(bodyLogs, LOG1).subscribe();
  }

	checkDuplicateSession() {
		this._store
			.select((store) => store.authorize.duplicateSession)
			.subscribe((duplicateSession) => {
				if (duplicateSession) {
					this.duplicateSession = true;
				}
			});
	}


	defineExperience(dataUserSession: any) {
    console.log("entra a defineexperience");
		this.mostrarFooter = false;
		this.cobranzaService.defineExperience(dataUserSession).subscribe({
			next: (response) => {
        console.log("response define expr consumo offer" );
				if (response.customerData.alternativeType === "Mantenimiento") {
          console.log("redirige mantenimiento");
					sessionStorage.setItem(
						"callDays",
						response.customerData.callDays
					);
					this._store.dispatch(
						new LoadInCacheAlternativesSuccess(response)
					);
					this.router.navigate(["/mantenimientos/listado-creditos"]);
					this.mostrarFooter = true;
				} else {
          console.log("else definexpr aterrizaje;")
					this.router.navigate(["/aterrizaje"]);
					this.mostrarFooter = true;
				}
			},
			error: (error : IClientOk) => {
        this._store.dispatch(new LoadClientOkFailed(error));
        console.log("error defineExperience");
				this.mantenimientosService
					.registerLog("2A_Ver_Bienvenida", error)
					.subscribe();
				this.mostrarFooter = true;
				this.router.navigate(["/mantenimientos/error"]);
			}
		});
	}

	userInactive() {
		this.countSecondsInactivity = this.maxTimeInactivity;
		this.isMovingMouse = false;
		this.controlInactivity = setInterval(() => {
			this.countSecondsInactivity--;
			if (this.isMovingMouse) {
				this.countSecondsInactivity = this.maxTimeInactivity;
				this.isMovingMouse = false;
			}
			if (this.countSecondsInactivity === this.alertTimeInactivity) {
				this.openModalCuentaRegresiva = true;
			}
			if (this.openModalCuentaRegresiva) {
				this.changeModalContent();
			}
			if (this.countSecondsInactivity === ZERO) {
				this.closeModalInativity = true;
				this.finishTimer();
			}
		}, THOUSAND);
	}

	checkMoveMouse() {
		document.onmousemove = () => {
			if (!this.openModalCuentaRegresiva) {
				this.isMovingMouse = true;
			}
		};
	}

	finishTimer() {
		this.openModalCuentaRegresiva = false;
		this.openModalSessionExpire = true;
		clearInterval(this.controlInactivity);
		this.closeSesion();
	}

	changeModalContent() {
		this.minutes = this.formatTime(
			Math.trunc(this.countSecondsInactivity / SIXTY)
		);
		this.seconds =
			this.formatTime(this.countSecondsInactivity % SIXTY) + " s";
		this.dataM2.tex2 = this.minutes + ":" + this.seconds;

		this.dataM2.slidersValue = this.calculatePercentage();
	}

	calculatePercentage() {
		return Math.trunc(
			(CIEN_PORCIENTO * this.countSecondsInactivity) /
				this.alertTimeInactivity
		);
	}


	formatTime(time: number): string {
		const IS_NUMBER_10: number = 10;
		return (time < IS_NUMBER_10 ? "0" : "") + String(time);
	}

	closeSesion() {
		this.mantenimientosService.logoutService().subscribe();
  }

	abrirModalSessionExpire() {
		this.openSessionExpire = true;
	}

	redirectToNegociarDeuda() {
		window.location.href =
			"https://www.bancolombia.com/personas/alternativas-financieras/negociar-deuda";
	}

	cerrarModalCuentaRegresiva() {
		clearInterval(this.controlInactivity);
		this.openModalCuentaRegresiva = false;
		this.userInactive();
	}
}
