// Objeto modal con valores por defecto - se toman en caso de que no se manda objeto
export const DATA_MODAL = {
	relo: false,
	progres: false,
	slidersValue: 0,
	btnAbrirM: "Texto boton abrir modal",
	typeButton: "primary",
	stylesBtn: {
		width: "302px"
	},

	iconoSe: "",
	arialLab: "",
	tamaIcon: "100px",

	titule: "Titulo del modal",

	tex1: "Texto obligatorio",
	styleText1: {},
	tex1Part2: "",
	styleText1Part2: {},
	tex1Part3: "",
	styleText1Part3: {},

	tex2: "Texto alternativo 1",
	styleText2: {},
	t2: false,

	tex3: "Texto alternativo 3",
	styleText3: {},
	t3: false,

	texBtn1: "Texto boton 1",
	pathBtn1: "",
	texBtn2: "Texto boton 2",
	pathBtn2: "",
	btn2: false,

	noVisibleX: false,
	outerCloseTap: true
};

export const DATA_EXPIROSESION = {
	relo: true,
	progres: false,
	slidersValue: 0,
	btnAbrirM: "",
	typeButton: "",
	stylesBtn: {},

	iconoSe: "",
	arialLab: "",
	tamaIcon: "100px",

	titule: "La sesión ha caducado",

	tex1: "Cerramos la sesión porque has superado el tiempo límite de inactividad.",
	styleText1: {},
	tex1Part2: "",
	styleText1Part2: {},
	tex1Part3: "",
	styleText1Part3: {},

	tex2: "Texto alternativo 1",
	styleText2: {},
	t2: false,

	tex3: "Texto alternativo 3",
	styleText3: {},
	t3: false,

	texBtn1: "SALIR",
	pathBtn1: "",
	texBtn2: "Texto boton 2",
	pathBtn2: "",
	btn2: false,

	noVisibleX: true,
	outerCloseTap: false
};

export const DATA_CUENTA_REGRE = {
	relo: false,
	progres: true,
	slidersValue: 100,
	btnAbrirM: "",
	typeButton: "",
	stylesBtn: {},

	iconoSe: "",
	arialLab: "",
	tamaIcon: "100px",

	titule: "¿Sigues con nosotros?",

	tex1: "Por seguridad tu sesión cerrará en:",
	styleText1: {},
	tex1Part2: "",
	styleText1Part2: {},
	tex1Part3: "",
	styleText1Part3: {},

	tex2: "02:00 s",
	styleText2: {
		fontSize: "40px",
		fontWeight: "700",
		marginTop: "40px"
	},
	t2: true,

	tex3: "Texto alternativo 3",
	styleText3: {},
	t3: false,

	texBtn1: "¡SIGO AQUÍ!",
	pathBtn1: "",
	texBtn2: "Texto boton 2",
	pathBtn2: "",
	btn2: false,

	noVisibleX: true,
	outerCloseTap: false
};
