import { Component, Inject, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { IAppState } from "src/app/store/states/app.state";
import { ChangeGuard, splitHour } from "../../../utils/utils";
import { IClientOk} from "../../cobranza-digital/store/states/clientOk.state";
import { LOCATION_TOKEN } from "../../../utils/location";
import { IData } from "../../cobranza-digital/store/states/autenticated.state";
import { CobranzaService } from "src/app/services/cobranza.service";


@Component({
	selector: "app-system-not-available",
	templateUrl: "./system-not-available.component.html",
	styleUrls: ["./system-not-available.component.scss"],
  providers: [
		{ provide: LOCATION_TOKEN, useValue: window.location }
	]
})
export class SystemNotAvailableComponent implements OnInit {
	data: any;

	day: string = "";
	dayNext: string = "";
  codeError : string = "ERROR404";
	month: string = "";
	monthNext: string = "";
	timeHours: string = "";
  guard: ChangeGuard;

	constructor(
    private cobranzaService: CobranzaService,
    private _store: Store<IAppState>,
    @Inject(LOCATION_TOKEN) public location: Location) {
      this.guard = new ChangeGuard(this._store, this.location);
    }

	ngOnInit(): void {
    scrollTo(0, 0);
    this.getCodeError();
		this.getAutenticatedStore();
	}

	exitExperience() {
		let a, c;
    this._store
      .select((state) => state.autenticated.data)
      .subscribe((response : IData) => {
        a = response.sessionToken;
        c = response.documentNumber;
        this.cobranzaService.getLogoutService(a, c).subscribe({
			next: () => {
			  this.guard.logoutItems(a,c);
			},
			error: () => {
			  this.guard.logoutItems();
			}
		});
      });
	}

	getAutenticatedStore() {
		this._store
			.select((state) => state.autenticated.data)
			.subscribe((response) => {
				this.data = response;
				this.setLastVisit(
					this.data.lastEntryDate,
					this.data.lastEntryHour
				);
			});
	}

  getCodeError() {
		this._store
			.select((state) => state.clientOk).subscribe(
        (res : IClientOk) => {
          if(res['clientOk']){
            res = res['clientOk'];
          }
          if(res.error.text !== "Paco" && res.error !== null){
            this.codeError = res.error.errorDetails.functionalErrorCode;
          }else{
            this.codeError = "ERROR404";
          }

        }
			);
	}

	setLastVisit = (date: string, hour: string) => {
		const { hours, minutes, seconds } = splitHour(hour);

		const options: object = {
			weekday: "long",
			year: "numeric",
			month: "long",
			day: "numeric",
			hour: "numeric",
			minute: "numeric",
			hour12: true
		};

		const dateAux = new Date(date);
		dateAux.setHours(hours, minutes, seconds);
		const dateCompleteAux = dateAux.toLocaleDateString("es-MX", options);
		const arrayDate = dateCompleteAux.split(" ");
    const num1 : number = 1, num2 : number = 2, num3 : number = 3, num4 : number = 4, num5 : number = 5, num6 : number = 6, num7 : number = 7;
		this.day = arrayDate[0].slice(0, arrayDate[0].length - 1);
		this.dayNext = `${arrayDate[num1]} ${arrayDate[num2]}`;

		this.month = arrayDate[num3];
		this.monthNext = `${arrayDate[num4]} ${arrayDate[num5]}`;
		this.timeHours = `${arrayDate[num6]} ${arrayDate[num7]}`;
	};
}
