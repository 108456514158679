import { Action } from "@ngrx/store";
import { MaintenanceInfo } from "../states/Maintenance-Info.state";

export enum EMaintenanceInfo {
  LOAD_MAINTENANCE_INFO_SUCCESS = "[Maintenance Info] Load Maintenance Info Success",
}

export class LoadMaintenanceInfoSuccess implements Action {
  readonly type = EMaintenanceInfo.LOAD_MAINTENANCE_INFO_SUCCESS;
  constructor(public payload: MaintenanceInfo) {}
}

export type MaintenanceInfoAction =
  | LoadMaintenanceInfoSuccess;
