import { EMaintenanceInfo, MaintenanceInfoAction } from "../actions/Maintenance-Info.action";
import { initialStateMaintenanceInfo } from "../states/Maintenance-Info.state";

export function maintenanceInfoReducer(
  state= initialStateMaintenanceInfo,
  action: MaintenanceInfoAction
) {
  switch (action.type) {
    case EMaintenanceInfo.LOAD_MAINTENANCE_INFO_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
}
