import { Action } from "@ngrx/store";
import { SimulationData } from "../states/SimulationData.state";

export enum ESimulationData {
  LOAD_SIMULATION_DATA_SUCCESS = "[Simulation Data] Load Simulation Data Success",
  LOAD_SIMULATION_DATA_FAIL = "[Simulation Data] Load Simulation Data Failed",
}

export class LoadSimulationDataSuccess implements Action {
  readonly type = ESimulationData.LOAD_SIMULATION_DATA_SUCCESS;
  constructor(public payload: SimulationData) {}
}


export type SimulationDataAction =
  | LoadSimulationDataSuccess
