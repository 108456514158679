<app-modal
	[ejecu]="open"
	[(dataM)]="dataxModalCancelar"
	(clickBtn1)="cerrarModalConfirmar($event)"
	(clickBtn2)="logoutMantenimientos()"
	(ejecuChange)="cerrarModalConfirmar($event)"
></app-modal>



