import { Component } from '@angular/core';

@Component({
  selector: 'app-stroke-firma',
  templateUrl: './stroke-firma.component.html',
  styleUrls: ['./stroke-firma.component.scss']
})
export class StrokeFirmaComponent {

}
