<div class="wait">
	<div class="center">
    <div class="yellow-circle">
      <em class="bc-icon bc-md center animation" aria-hidden="true">refresh</em>
    </div>
    <div class="center-text">
      <div class="limit-width">
        <h4 class="bc-mt-4">{{message}}</h4>
      </div>
    </div>
	</div>
</div>
