import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icono-carga',
  templateUrl: './icono-carga.component.html',
  styleUrls: ['./icono-carga.component.scss']
})
export class IconoCargaComponent {
  @Input() message: string = "Por favor espera mientras validamos la información";
}
