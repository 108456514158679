import { Component, Inject, OnInit } from "@angular/core";
import { LOCATION_TOKEN } from "src/app/utils/location";
import { URL_BANCOLOMBIA_PERSONAS_DEUDA } from "./constants";

@Component({
	selector: "app-logout",
	templateUrl: "./logout.component.html",
	styleUrls: ["./logout.component.scss"],
	providers: [
		{ provide: LOCATION_TOKEN, useValue: window.location },
		{
			provide: URL_BANCOLOMBIA_PERSONAS_DEUDA,
			useValue: URL_BANCOLOMBIA_PERSONAS_DEUDA
		}
	]
})
export class LogoutComponent implements OnInit {
	constructor(@Inject(LOCATION_TOKEN) public location: Location) {}

	ngOnInit(): void {
		this.location.hash = "no-back-button";
		this.location.hash = "Again-No-back-button"; //chrome
		window.onhashchange = () => {
			window.location.hash = "no-back-button";
		};
		this.logout(URL_BANCOLOMBIA_PERSONAS_DEUDA);
	}

	logout(url: string = ""): void {
		this.location.assign(url);
	}
}
