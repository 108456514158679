export interface IClientOk {
  error : ErrorCaptured,
	message: string,
  name: string,
  ok: boolean,
  status: number,
  statusText: string
}

export interface ErrorCaptured {
	text: string,
  message: string,
  errorCodeHTTP: string,
  errorDescription: string,
  errorNameHTTP: string,
  errorDetails: ErrorDet
}

export interface ErrorDet {
	exceptionType: string,
  functionalErrorCode: string,
  functionalErrorDescription: string,
  operationName: string,
  serviceName: string
}



export const initialStateClientOk: IClientOk = {
	error : {
    text: "Paco",
    message: "",
    errorCodeHTTP: "",
    errorNameHTTP: "",
    errorDescription: "",
    errorDetails: {
        exceptionType: "",
        functionalErrorCode: "",
        functionalErrorDescription: "",
        operationName: "",
        serviceName: ""
    }
  },
	message: "",
  name: "",
  ok: false,
  status: 200,
  statusText: ""
};

export const initialStateClientOkError: IClientOk = {
	error : {
    text: "Ok!",
    message: "",
    errorCodeHTTP: "",
    errorNameHTTP: "",
    errorDescription: "",
    errorDetails: {
        exceptionType: "",
        functionalErrorCode: "000",
        functionalErrorDescription: "",
        operationName: "",
        serviceName: ""
    }
  },
	message: "",
  name: "",
  ok: false,
  status: 200,
  statusText: ""
};


export const iClientOkErrorMsg: IClientOk = {
	error : {
    text: "Ok!",
    message: "AUTH004",
    errorCodeHTTP: "",
    errorNameHTTP: "",
    errorDescription: "",
    errorDetails: {
        exceptionType: "",
        functionalErrorCode: "AUTH010",
        functionalErrorDescription: "",
        operationName: "",
        serviceName: ""
    }
  },
	message: "",
  name: "",
  ok: false,
  status: 200,
  statusText: ""
};

export const iClientOkErrorMsgStTx: IClientOk = {
	error : {
    text: "fail",
    message: "AUTH004",
    errorCodeHTTP: "",
    errorNameHTTP: "",
    errorDescription: "",
    errorDetails: {
        exceptionType: "",
        functionalErrorCode: "OFFER010",
        functionalErrorDescription: "",
        operationName: "",
        serviceName: ""
    }
  },
	message: "",
  name: "",
  ok: false,
  status: 500,
  statusText: ""
};

