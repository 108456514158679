import { Injectable } from '@angular/core';
import { UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { IClientOk } from '../layout/cobranza-digital/store/states/clientOk.state';
import { LoadClientOkFailed } from '../layout/cobranza-digital/store/actions/clientOk.action';
import { Store } from "@ngrx/store";
import { IAppState } from "src/app/store/states/app.state";
import { environment } from "src/environments/environment";


@Injectable({
  providedIn: 'root',
})

export class PermissionsGuard2  {
  env: boolean = environment.production;
  constructor(
		private router: Router,
    private _store: Store<IAppState>
	) {}

  errrSesion : IClientOk ={
    error : {
      text: "Ingreso no permitido",
      message: "Ingreso no permitido",
      errorCodeHTTP: "",
      errorNameHTTP: "",
      errorDescription: "",
      errorDetails: {
        exceptionType: "USERSESSION",
        functionalErrorCode: "USERSESSION",
        functionalErrorDescription: "USERSESSION",
        operationName: "",
        serviceName: ""
      }
    },
    message: "Ingreso no permitido",
    name: "",
    ok: false,
    status: 500,
    statusText: ""
  };
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let res: boolean = true;

    if(!this.env){
      return res;
    }else{
        res = false;
        this._store.dispatch(new LoadClientOkFailed(this.errrSesion));
        this.router.navigate(["/logout"]);
        return res;
    }
  }
}
