import { ActionReducerMap } from "@ngrx/store";

import { IAppState } from "../states/app.state";
import { alternativesReducer } from "src/app/layout/cobranza-digital/store/reducers/alternatives.reducer";
import { alternativesInCacheReducer } from "src/app/layout/cobranza-digital/store/reducers/alternativesInCache.reducer";
import { autenticatedReducer } from "src/app/layout/cobranza-digital/store/reducers/autenticated.reducer";
import { authorizeReducer } from "src/app/layout/cobranza-digital/store/reducers/authorize.reducer";
import { clientOkReducer } from "src/app/layout/cobranza-digital/store/reducers/clientOk.reducer";
import { maintenanceInfoReducer } from "src/app/layout/mantenimientos/store/reducers/Maintenance-Info.reducer";
import { simulationDataReducer } from "src/app/layout/mantenimientos/store/reducers/SimulationData.reducer";

import { negotiationInfoReducer } from "src/app/layout/firma-electronica/store/reducers/negotiation-info.reducer";
import { clientInfoReducer } from "src/app/layout/firma-electronica/store/reducers/client-info.reducer";
import { IclientInfo } from '../../layout/firma-electronica/store/states/client-info.state';

export const appReducer: ActionReducerMap<IAppState, any> = {

	alternatives: alternativesReducer,
	alternativesInCache: alternativesInCacheReducer,
	autenticated: autenticatedReducer,
  clientOk: clientOkReducer,
  authorize: authorizeReducer,
  Maintenance_Info: maintenanceInfoReducer,
  SimulationData: simulationDataReducer,
  negotiationInfo: negotiationInfoReducer,
  infoClient : clientInfoReducer
};
