export interface IAlternativesCache {
	customerData: ICustomerData;
	error: boolean;
  code: string;
}

export interface ICustomerData {
	expirationDate: string;
	alternativeType: string;
	state: string;
	customerName: string;
	mobilePhone: string;
	callDays: string;
	actionCode: number;
  opportunityId: string;
  obligaciones: string[];
  newMobilePhone : string;
}

export const initialStateAlternativesCache: IAlternativesCache = {
	customerData: {
		expirationDate: "",
		alternativeType: "",
		state: "",
		customerName: "",
		mobilePhone: "",
		callDays: "",
		actionCode: 0,
    opportunityId: "",
    obligaciones: ["",""],
    newMobilePhone: ""
	},
	error: false,
  code: "000"
};
