export interface IclientInfo{
  firstName : string,
  firstSurname : string,
  customerName : string,
  mobilePhone : string,
  email : string
}

export const initialInfoClient : IclientInfo = {
    firstName: "",
    firstSurname: "",
    customerName: "",
    mobilePhone: "",
    email: ""
}

