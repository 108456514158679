<button
id="btn-modal-defecto"
	bc-button
	[typeButton]=" dataM.typeButton"
	sizeButton="small"
	[ngStyle]="dataM.stylesBtn"
	(click)="openM()"
	*ngIf="botonPorDefecto"
  class="button"
>
	{{dataM.btnAbrirM}}
	<!-- texto de boton  poner ng if-->
</button>

<div class="contenedor-modal">
	<bc-modal
	#modalAll
	[title]=""
	[typeIcon]="false"
	[customWidth]="customWidth"
	[titleAlign]="titleAlign"
	[isCancellable]="botonSecundario"
	[subTitle]=""
	[displayButton]="true"
	[buttonAlignment]="buttonAlignment"
	[textBtn]=""
	[textBtnLeft]=""
	typeNotify=""
	[hideCloseButton]="dataM.noVisibleX"
	[outerCloseTap]="false"
  (closeModalEvent)="closeModalHideButton()"
>
	<div modalContent>

		<div>
			<div class="contImTil">
				<div>
					<bc-icon
          id="icono-modal-defecto"
						aria-hidden="true"
						[name]="dataM.iconoSe"
						[aria-label]="dataM.arialLab"
						role="img"
						[width]="dataM.tamaIcon"
						class="bc-sm"
					></bc-icon>
				</div>
				<div>
					<!-- Titulo modal -->
					<h4 id="titulo-modal">{{dataM.titule}}</h4>
					<!--  Texto 1 modal -->
					<p id="p1-parte1-modal" class="p1" [ngStyle]="dataM.styleText1">
						{{dataM.tex1}}
					</p>
					<p id="p1-parte2-modal" class="p1" [ngStyle]="dataM.styleText1Part2">
						{{dataM.tex1Part2}}
					</p>
					<p id="p1-parte3-modal" class="p1" [ngStyle]="dataM.styleText1Part3">
						{{dataM.tex1Part3}}
					</p>
					<!--  Texto 2 modal -->
					<p id="p2-parte2-modal" class="p2" [ngStyle]="dataM.styleText2" *ngIf="dataM.t2">
						{{dataM.tex2}}
					</p>
					<!--  Texto 3 modal -->
					<p id="p2-parte3-modal" class="p3" [ngStyle]="dataM.styleText3" *ngIf="dataM.t3">
						{{dataM.tex3}}
					</p>
					<div class="botones">
						<div class="boton1">
							<button
              					id="btn-1-modal"
								bc-button
								typeButton="primary"
								sizeButton="small"
								class="btn-primary-modal"
								(click)="fnBtnPrimary()"
								[routerLink]="dataM.pathBtn1 ? dataM.pathBtn1 : null"
							>
								{{dataM.texBtn1}}
							</button>
						</div>
						<div class="boton2" *ngIf="dataM.btn2">
							<button
              					id="btn-2-modal"
								bc-button
								typeButton="secondary"
								sizeButton="small"
								class="btn-secundary-modal"
								(click)="fnBtnSecundary()"
							>
								{{dataM.texBtn2}}
							</button>
						</div>
            <div id="btn-cancelar-modal" class="boton2 can" *ngIf="dataM.can">
                <a (click)="fnBtnCancel()">
								Cancelar
                </a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</bc-modal>
</div>
