<div class="wrapper">
  <div class="content">
    <app-navbar *ngIf="mostrarFooter"></app-navbar>
    <router-outlet></router-outlet>
  </div>
  <app-moda-esp
    [dataM]="dataM"
    [ejecu]="openModalSessionExpire"
    [botonPorDefecto]="viewBtnExpire"
    [textoBoton]="dataM.texBtn1"
    (clickBtn1)="redirectToNegociarDeuda()"
  ></app-moda-esp>
  <app-moda-esp
    [dataM]="dataM2"
    [ejecu]="openModalCuentaRegresiva"
    [closeEjecu]="closeModalInativity"
    [botonPorDefecto]="viewBtnCuentRegresiva"
    [textoBoton]="dataM2.texBtn1"
    (clickBtn1)="cerrarModalCuentaRegresiva()"
  ></app-moda-esp>
  <app-modal
    [dataM]="dataModal"
    [botonPorDefecto]="viewBtnModal"
    [ejecu]="duplicateSession"
    (clickBtn1)="redirectToNegociarDeuda()"
  >
  </app-modal>
  <app-footer *ngIf="mostrarFooter"></app-footer>
</div>
