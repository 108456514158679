import { Component, Inject, OnInit } from "@angular/core";
import { MantenimientosService } from "src/app/services/mantenimientos.service";
import { MaintenanceInfo } from "src/app/layout/mantenimientos/store/states/Maintenance-Info.state";
import { Store } from "@ngrx/store";
import { IAppState } from "src/app/store/states/app.state";
import { ChangeGuard } from "src/app/utils/utils";
import { CobranzaService } from "src/app/services/cobranza.service";
import { Router } from "@angular/router";
import { IClientOk } from "../../../layout/cobranza-digital/store/states/clientOk.state";
import { LOCATION_TOKEN } from "../../../utils/location";
import { IData } from "../../../layout/cobranza-digital/store/states/autenticated.state";
import { LoadClientOkFailed } from "../../../layout/cobranza-digital/store/actions/clientOk.action";
import { DOCUMENT } from "@angular/common";

@Component({
	selector: "app-navbar",
	templateUrl: "./navbar.component.html",
	styleUrls: ["./navbar.component.scss"],
	providers: [{ provide: LOCATION_TOKEN, useValue: window.location }]
})
export class NavbarComponent implements OnInit {
	openN: boolean = false;
	guard: ChangeGuard;
	strokeMantenimientos = false;
	maintenanceInfo: MaintenanceInfo;
	stateAlternativeEmpty: number = 0;
	openConfirm: boolean = false; // Variable que abre el modal Confirmar
	isFirma: boolean = false;
	currentURL: string;
	tipoHeader: string = "bc-header-wrapper";

	constructor(
		private readonly cobranzaService: CobranzaService,
		private readonly router: Router,
		private readonly _store: Store<IAppState>,
		private readonly _mantenimientosService: MantenimientosService,
		@Inject(LOCATION_TOKEN) public location: Location,
		@Inject(DOCUMENT) document: any
	) {
		this.guard = new ChangeGuard(this._store, this.location);
	}

	ngOnInit(): void {
		this._store
			.select((state) => state.Maintenance_Info)
			.subscribe((response) => {
				this.maintenanceInfo = response;
				this.countMaintenancesWithEmptyStateAlternative();
			});
		this.currentURL = document.location.href;
	}

	countMaintenancesWithEmptyStateAlternative() {
		if (this.maintenanceInfo?.maintenanceList) {
			this.stateAlternativeEmpty =
				this.maintenanceInfo.maintenanceList.filter(
					(item) => item.stateAlternative === "En blanco"
				).length;
		} else {
			this.stateAlternativeEmpty = 0;
		}
	}

	cerrarModalConfirmar(event: boolean) {
		if (event) {
			this.openN = false;
		}
	}

	logout() {
		let a, c;
		this._store
			.select((state) => state.autenticated.data)
			.subscribe((response: IData) => {
				a = response.sessionToken;
				c = response.documentNumber;
			});
		this.cobranzaService.getLogoutService(a, c).subscribe({
			next: () => {
				this.openN = false;
				this.guard.logoutItems(a, c);
			},
			error: (e: IClientOk) => {
				this._store.dispatch(new LoadClientOkFailed(e));
				this.router.navigate(["/error"]);
			}
		});
	}

	abrirModal() {
		const currentURL = document.location.href;
		const mantenimientosPaths = [
			"mantenimientos/cliente-al-dia",
			"mantenimientos/recibir-llamada",
			"mantenimientos/fuera-de-horario"
		];

		const mantenimientoPath = mantenimientosPaths.some((path) =>
			currentURL.includes(path)
		);
		const confirmacionNegociacion =
			currentURL.includes("mantenimientos/confirmacion-negociacion") &&
			this.stateAlternativeEmpty === 1;

		if (mantenimientoPath || confirmacionNegociacion) {
			this._mantenimientosService.logoutService().subscribe(() => {
				window.location.href =
					"https://www.bancolombia.com/personas/alternativas-financieras/negociar-deuda";
			});
		} else {
			this.openN = true;
		}
	}

	get hiddenNav(): boolean {
		const currentURL = document.location.href;
		if (currentURL.includes("firmaelectronica")) {
			this.isFirma = true;
		}
		const hiddenNavbar = [
			"beneficiarios",
			"asegurabilidad",
			"aceptar-documento"
		];
		return !hiddenNavbar.some(element => currentURL.includes(element));
	}
}
