import { ClientAction, EClientInfo } from "../actions/client-info.action";
import { IclientInfo, initialInfoClient } from "../states/client-info.state";


export function clientInfoReducer(
	state = initialInfoClient,
	action: ClientAction
): IclientInfo {
	switch (action.type) {
    case EClientInfo.LOAD_CLIENTNINFO_SUCCESS: {
      return {
        ...state,
        ...action.payload
			};
		}
    default:
      return state;
  }
}
