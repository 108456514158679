<div class="footer-area" *ngIf="hiddenNav">
	<div *ngIf="!dark" class="copyright-area">
		<div class="contenedor-info">
			<div class="row mobile">
				<div class="bc-col contenedor-logo">
					<div>
						<div class="logo-mobile">
							<bc-logo
								size="170px"
								width="5rem"
								name="grupo-bancolombia-negative"
								[ariaHidden]="false"
								alt="logo"
							></bc-logo>
						</div>
            <div class="logo-web">
							<bc-logo
								size="170px"
								width="5rem"
								name="bancolombia-horizontal-negative"
								[ariaHidden]="false"
								alt="logo"
							></bc-logo>
						</div>
					</div>
					<div class="copyright-area-dark">
						<span class="copyright">
							Copyright © 2024 Grupo Bancolombia
						</span>
					</div>
				</div>

				<div class="bc-col text-end mobile-ip">
					<!-- API Conect -->

					<p>
						Dirección IP {{(datax.ipClient !== null &&
						datax.ipClient !== undefined) ? datax.ipClient :
						''}}
					</p>
					<span
						>Última visita
						<span class="capitalize">{{day}}</span></span
					>
					<span> {{dayNext}}</span>
					<span class="capitalize"> {{month}} </span>
					<span> {{monthNext}}</span>
					<span> {{timeHours}}</span>
				</div>
			</div>
		</div>
	</div>
</div>
