import { Action } from "@ngrx/store";
import { IDataNegotiation } from "../states/negotiation-info.state";

export enum ENegotiationInfo {
	LOAD_NEGOTIATIONINFO_SUCCESS = "[NegotiationInfOk] get Negotiation Success",
	LOAD_NEGOTIATIONINFO_FAIL = "[NegotiationInfOk] get Negotiation Failed",
  LOAD_ALTERNATIVES_SELECTED_NEG_SUCESS = "[AlternativeSelectedNegOk] get AlternativeSelectedNeg Success",
  LOAD_ALTERNATIVES_SELECTED_NEG_FAIL = "[AlternativeSelectedNegOk] get AlternativeSelectedNeg Failed",
	UPDATE_DOCUMENT_STATE = "[UpdateStateDocumentOk] update document state",
}

export class LoadNegotiationInfoSuccess implements Action {
	readonly type = ENegotiationInfo.LOAD_NEGOTIATIONINFO_SUCCESS;
	constructor(public payload: IDataNegotiation) {}
}

export class LoadNegotiationInfoFailed implements Action {
	readonly type = ENegotiationInfo.LOAD_NEGOTIATIONINFO_FAIL;
	constructor(public payload: boolean) {}
}
export class UpdateDocumentState implements Action {
	readonly type = ENegotiationInfo.UPDATE_DOCUMENT_STATE;
	constructor(public payload: { numberConsolidate: string, nameDocument : string, statusDocument: string }) {}
}


export type NegotiationAction =
	| LoadNegotiationInfoSuccess
	| LoadNegotiationInfoFailed
  | UpdateDocumentState;


